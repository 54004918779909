/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment, Grid, GridColumn, Icon, Image } from 'semantic-ui-react';
import { map } from 'lodash';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import Logo_descr from '../../../../../theme/assets/images/Logo_descr.svg';
import Socialm_tw_white from '../../../../../theme/assets/images/Socialm_tw_white.svg';
import Socialm_fb_white from '../../../../../theme/assets/images/Socialm_fb_white.svg';
import Socialm_ig_white from '../../../../../theme/assets/images/Socialm_ig_white.svg';
import Socialm_li_white from '../../../../../theme/assets/images/Socialm_li_white.svg';
import config from '@plone/volto/registry';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const { settings } = config;
  const { lang, siteActions = [] } = useSelector(
    (state) => ({
      lang: state.intl.locale,
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );

  return (
    <Segment
      role="contentinfo"
      vertical
      padded
      inverted
      textAlign="center"
      id="footer"
    >
      <Container>
        <Grid textAlign="left" columns={3}>
          <Grid.Column>
            <Image
	      id="footer_logo"
	      src={Logo_descr}
	      alt="GeoSphere Austria"
	      title="GeoSphere Austria"
	      height={109}
	    />
          </Grid.Column>
          <Grid.Column>
            <p>
              GeoSphere Austria<br />1190 Wien, Hohe Warte 38<br />+43 1 36026<br />kontakt@geosphere.at<br />presse@geosphere.at
            </p>
            <span>
	      <span className="socialm">
	        <a href="https://www.facebook.com/GeoSphere.at">
	        <Image
	          src={Socialm_fb_white} 
	          alt="GeoSphere Austria on Facebook"
	          title="GeoSphere Austria on Facebook"
	          height={20}
	        />
	        </a>
	        <a href="https://twitter.com/GeoSphere_AT">
	        <Image
	          src={Socialm_tw_white} 
	          alt="GeoSphere Austria on Twitter"
	          title="GeoSphere Austria on Twitter"
	          height={20}
	        />
	        </a>
	        <a href="https://www.instagram.com/GeoSphere_AT">
	        <Image 
	          src={Socialm_ig_white} 
	          alt="GeoSphere Austria on Instagram"
	          title="GeoSphere Austria on Instagram"
	          height={20}
	        />
	        </a>
	        <a href="https://at.linkedin.com/company/geosphereaustria">
	        <Image
	          id="sm_li"
	          src={Socialm_li_white} 
	          alt="GeoSphere Austria on LinkedIn"
	          title="GeoSphere Austria on LinkedIn"
	          height={20}
	        />
	        </a>
	      </span>
	      <span className="vbar">
	        <ul>
	          <li><UniversalLink href="/de/legal">Legal</UniversalLink></li>
	          <li><UniversalLink href="/de/impressum">Impressum</UniversalLink></li>
	        </ul>
	      </span>
            </span>
          </Grid.Column>
          <Grid.Column>
            <h3>Bestehende Websites</h3>
            <p>
              Besuchen Sie uns auch auf den folgenden Seiten: 
            </p>
            <List inverted>
              <List.Content>
                <List.Item>
                  <UniversalLink href="https://www.zamg.ac.at">Alte Website ZAMG</UniversalLink>
                </List.Item>
                <List.Item>
                  <UniversalLink href="https://www.geologie.ac.at">Alte Website Geologische Bundesanstalt</UniversalLink>
                </List.Item>
              </List.Content>
            </List>
          </Grid.Column>
        </Grid>
      </Container>
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);

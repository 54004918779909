/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import plonevoltoSlate from '@plone/volto-slate';
import eeacmsvoltoAccordionBlock from '@eeacms/volto-accordion-block';
import kitconceptvoltoSliderBlock from '@kitconcept/volto-slider-block';
import eeacmsvoltoMatomo from '@eeacms/volto-matomo';
import voltoweb from 'voltoweb';
import rohbergvoltoSearchkitBlock from '@rohberg/volto-searchkit-block';

const addonsInfo = [{"modulePath":"/build/node_modules/@plone/volto/packages/volto-slate/src","packageJson":"/build/node_modules/@plone/volto/packages/volto-slate/package.json","version":"17.1.1","isPublishedPackage":false,"isRegisteredAddon":false,"name":"@plone/volto-slate","addons":[]},{"name":"@eeacms/volto-accordion-block","version":"10.4.5","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/build/node_modules/@eeacms/volto-accordion-block/src","packageJson":"/build/node_modules/@eeacms/volto-accordion-block/package.json","addons":[]},{"name":"@kitconcept/volto-slider-block","version":"6.1.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/build/node_modules/@kitconcept/volto-slider-block/src","packageJson":"/build/node_modules/@kitconcept/volto-slider-block/package.json","addons":[]},{"name":"@eeacms/volto-matomo","version":"4.2.1","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/build/node_modules/@eeacms/volto-matomo/src","packageJson":"/build/node_modules/@eeacms/volto-matomo/package.json","addons":[]},{"modulePath":"/build/src/addons/voltoweb/src","packageJson":"/build/src/addons/voltoweb/package.json","version":"0.1.0","isPublishedPackage":false,"isRegisteredAddon":true,"name":"voltoweb","addons":["@eeacms/volto-accordion-block","@kitconcept/volto-slider-block","@eeacms/volto-matomo"]},{"name":"@rohberg/volto-searchkit-block","version":"0.3.3","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/build/node_modules/@rohberg/volto-searchkit-block/src","packageJson":"/build/node_modules/@rohberg/volto-searchkit-block/package.json","addons":["@eeacms/volto-matomo"]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [plonevoltoSlate, eeacmsvoltoAccordionBlock, kitconceptvoltoSliderBlock, eeacmsvoltoMatomo, voltoweb, rohbergvoltoSearchkitBlock];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;

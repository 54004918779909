/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { Component } from 'react';
import { Container, Segment, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import Socialm_tw_green from '../../../../../theme/assets/images/Socialm_tw_green.svg';
import Socialm_fb_green from '../../../../../theme/assets/images/Socialm_fb_green.svg';
import Socialm_ig_green from '../../../../../theme/assets/images/Socialm_ig_green.svg';
import Socialm_li_green from '../../../../../theme/assets/images/Socialm_li_green.svg';

import {
  Anontools,
  LanguageSelector,
  Logo,
  Navigation,
} from '@plone/volto/components';

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Segment basic className="header-wrapper" role="banner">
        <Container>
          <div className="header">
	    <div className="logo-nav-wrapper">
              <div className="logo">
	        <Logo />
	      </div>
              <Navigation pathname={this.props.pathname} />
	    </div>
	    <div className="socialm-wrapper">
	        <span className="header_socialm">
	          <a href="https://www.facebook.com/GeoSphere.at">
	          <Image
	            src={Socialm_fb_green} 
	            alt="Geosphere Austria on Facebook"
	            title="Geosphere Austria on Facebook"
	            height={30}
	          />
	          </a>
	          <a href="https://twitter.com/GeoSphere_AT">
	          <Image
	            src={Socialm_tw_green} 
	            alt="Geosphere Austria on Twitter"
	            title="Geosphere Austria on Twitter"
	            height={30}
	          />
	          </a>
	          <a href="https://www.instagram.com/GeoSphere_AT">
	          <Image 
	            src={Socialm_ig_green} 
	            alt="Geosphere Austria on Instagram"
	            title="Geosphere Austria on Instagram"
	            height={30}
	          />
	          </a>
	          <a href="https://at.linkedin.com/company/geosphereaustria">
	          <Image 
	            src={Socialm_li_green} 
	            alt="Geosphere Austria on LinkedIn"
	            title="Geosphere Austria on LinkedIn"
	            height={30}
	          />
	          </a>
	        </span>
	    </div>
	  </div>
        </Container>
      </Segment>
    );
  }
}

export default connect((state) => ({
  token: state.userSession.token,
}))(Header);
